.react-multi-email.empty > span[data-placeholder] {
  @apply text-jll-color-coldGray-7;
}

.react-multi-email > span[data-placeholder] {
  @apply text-sm left-0 top-0 px-3 py-2;
}

.react-multi-email [data-tag]:first-child {
  @apply !ml-3;
}

.react-multi-email > input {
    @apply focus:!px-3;
}
