.rdrStaticRange {
  @apply text-jll-color-text-base-subdued relative p-0;
}

.rdrStaticRangeLabel {
  @apply text-jll-color-text-base-subdued block font-medium truncate px-5 py-2.5 text-base hover:bg-jll-color-surface-info-subdued hover:text-jll-color-surface-info-default;
}

.rdrInputRange {
  @apply text-jll-color-text-base-subdued font-medium text-sm;
}

.rdrDateInput {
  @apply active:border-jll-color-coldGray-7;
}

.rdrDateDisplayItemActive {
  @apply border-jll-color-surface-info-default bg-jll-color-surface-info-subdued shadow-none;
}

.rdrDateInput input {
  @apply block w-full rounded focus:border-jll-color-coldGray-7 focus:ring-jll-color-coldGray-7 sm:text-sm text-jll-color-text-base-default disabled:bg-jll-color-coldGray-2 border-jll-color-coldGray-4;
}

.rdrDateDisplayItemActive input {
  @apply text-jll-color-surface-info-default;
}

.rdrMonthPicker option,
.rdrYearPicker option {
  @apply text-jll-color-text-base-subdued block font-medium truncate px-5 py-2.5 text-base hover:bg-jll-color-surface-info-subdued hover:text-jll-color-surface-info-default;
}

.rdrMonthPicker select,
.rdrYearPicker select {
  @apply flex flex-row items-center w-full py-2 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm cursor-pointer rounded-[48px] border-0 font-semibold hover:bg-jll-color-surface-info-subdued hover:text-jll-color-surface-info-default text-jll-color-text-base-subdued;
}

.rdrDateDisplayWrapper {
  @apply bg-white;
}

.rdrMonthName,
.rdrMonthName,
.rdrWeekDay {
  @apply text-jll-color-text-base-subdued;
}

.rdrDayNumber {
  @apply text-jll-color-text-base-default;
}

.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  @apply !text-jll-color-surface-info-default;
}

.rdrInRange ~ .rdrDayNumber span,
.rdrStartEdge ~ .rdrDayNumber span {
  @apply !text-white font-semibold;
}

.rdrDayToday .rdrDayNumber span {
  @apply after:bg-jll-color-surface-info-default;
}

.rdrInputRangeInput {
  @apply block rounded focus:border-jll-color-coldGray-7 focus:ring-jll-color-coldGray-7 sm:text-sm text-jll-color-text-base-default disabled:bg-jll-color-coldGray-2 border-jll-color-coldGray-4;
}

.rdrMonthAndYearWrapper button {
    @apply hover:bg-jll-color-surface-info-subdued;
}
