.react-datepicker__month-container {
  border-color: #c7cace;
}

.react-datepicker__header {
  background: #f6f7f7;
  border-bottom: none;
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: #60666e;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

.react-datepicker__day:hover {
  color: white;
  background: #3a3e43;
}

.react-datepicker__current-month {
  padding: 10px 0px;
}

.react-datepicker__navigation {
  width: 50px;
  height: 50px;
}

.react-datepicker__day--selected {
  color: white;
  background: #3a3e43;
}

.react-datepicker__navigation-icon::before {
  top: 12px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
