h1 {
  @apply text-2xl font-bold;
}

h2 {
  @apply text-xl font-bold;
}

h3 {
  @apply text-lg font-bold;
}

h4 {
  @apply text-base font-semibold;
}

h5 {
  @apply text-sm font-semibold;
}

h6 {
  @apply text-xs font-semibold;
}

.ce-popover--opened {
  @apply right-[-60px];
}
