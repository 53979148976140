figure.image-hover-wrapper {
}
figure.image-hover-wrapper * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
figure.snip1205 .image-hover-icon {
  line-height: 60px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  transition: all 100ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
figure.image-hover-wrapper img {
  max-width: 100%;
  vertical-align: top;
}
figure.image-hover-wrapper:hover img {
  opacity: 0.3;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
figure.image-hover-wrapper:hover .image-hover-icon {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  transition: all 100ms 50ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
