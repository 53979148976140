/* Accordion styles */
.tab-label::after {
  content: '❯';
  width: 1em;
  text-align: center;
  transition: all 0.35s;
  color: #60666E;
}
.popup-accordion-input:checked + .tab-label::after {
  @apply rotate-90 origin-center;
}
.popup-accordion-input:checked ~ .tab-content {
  max-height: fit-content;
}

.dragging .tab-content {
  display: none!important;
}
