@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html{
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 24px;
}

.css-qbdosj-Input input:focus {
  border: none;
  box-shadow: none;
}

.slide-pane__overlay {
  @apply z-10;
}

.slide-pane__content {
  @apply !pr-0 !pb-0;
}

.react-tooltip {
  z-index: 20000;
}

.react-datepicker-wrapper {
  @apply !w-full;
}
